import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CBBot, ICBBot } from '../models/cb-bots';

const collectionName = 'CB_bots'
@Injectable({
  providedIn: 'root',
})
export class CBBotService {
  constructor(protected afs: AngularFirestore) {}

  getCBBot(name: string): Observable<CBBot|undefined> {
    return this.afs
      .collection<ICBBot>(collectionName, (ref) => ref.where('name', '==', name))
      .valueChanges()
      .pipe(
        map((bots) => {
          if (bots.length == 0) {
            return undefined;
          }
          return new CBBot(bots[0]);
        })
      );
  }

  getAllCBBots(): Observable<CBBot[]> {
    return this.afs
      .collection<ICBBot>(collectionName)
      .valueChanges()
      .pipe(
        map((bots) => {
          console.log('Getting all cb bots...');
          if (bots.length == 0) {
            return [];
          }
          return bots.map((bot) => new CBBot(bot));
        })
      );
  }
}
