import { BaseResource, BaseSerializer } from '../services/base.service';

export interface ICBBot {
  name: string;
  id: string;
  token: string;
  defaultSettings?: any;
  defaultUserGroupId?: string;
  defaultSchoolId?: string;
  chatGroupCategoryIds?: {
    [type: string]: string
  }
}

export class CBBot extends BaseResource {
  name: string;
  id: string;
  token: string;
  defaultSettings?: any;
  defaultUserGroupId?: string;
  defaultSchoolId?: string;
  chatGroupCategoryIds?: {
    [type: string]: string
  }

  constructor(obj: ICBBot) {
    super(obj);
    this.name = obj.name ?? '';
    this.id = obj.id ?? '';
    this.token = obj.token ?? '';
    this.defaultSettings = obj.defaultSettings;
    this.defaultUserGroupId = obj.defaultUserGroupId;
    this.defaultSchoolId = obj.defaultSchoolId;
    this.chatGroupCategoryIds = obj.chatGroupCategoryIds;
  }
}

export class CBBotSerializer extends BaseSerializer<CBBot> {
  fromJson(json: any): CBBot {
    return new CBBot(json);
  }

  toJson(resource: CBBot): any {
    return {
      ...resource,
    } as ICBBot;
  }
}