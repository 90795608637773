import { Injectable } from '@angular/core';
import { AxiosNestService } from './axios-nest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MailerService {
  constructor(private axiosNest: AxiosNestService,) {}

  send(to: string, subject: string, content: string, from?: string): Observable<any> {
    const params = {
        to: to,
        subject: subject,
        content: content,
        from: from,
    };
    return this.axiosNest
      .postObservable('sendEmail', params)
      .pipe(map((response) => {
        console.log(response);
        return response;
      }));
  }
}
